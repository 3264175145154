/* SCANLINES EFFECT */
.scanlines {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 2147483648;
}

.hidden {
  display: none; /* Hide scanlines when disabled */
}

.scanlines::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.3) 51%
    );
    background-size: 100% 4px;
    animation: scanlines 1s steps(60) infinite;
    z-index: 2147483648;
}

.scanlines::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0.4;
    animation: scanline 15s linear infinite;
}

/* ANIMATIONS */
@keyframes scanline {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes scanlines {
    0% {
        background-position: 0 50%;
    }
}

