.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #008080;
  z-index: -1;
  overflow: hidden;
}

.draggable-window {
  top: 50px; /* Adjust starting position as needed */
  left: 50px; /* Adjust starting position as needed */
  width: auto; /* Initial width */
  height: auto; /* Initial height */
  background-color: #c3c3c3;
  box-shadow: var(--border-window-outer), var(--border-window-inner);
  padding: 3px;
  margin: auto; /* Center horizontally */
  position: relative;
  display: inline-block; /* Prevents it from taking full width */
  box-sizing: border-box;
  transition: top 0.3s ease, left 0.3s ease, width 0.3s ease, height 0.3s ease;
}

.draggable-window.maximized {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: auto;
}

.draggable-window.maximized .window{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  
}

.navbar-container {
  padding:2px;
  box-shadow:inset 1px 0 #fff;
  display:block;
  background-color:silver;
  bottom:0;
  left:0;
  right:0;
  border-top:1px solid #f4f4f4;
  border-bottom:1px solid #4e4e4e;
  position:fixed;
  z-index:99999;
}

.start-button {
  background-image: url(../public/start-button.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 45px;
  height: 14px;
}

.button-text {
  line-height: normal;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.camera-button {
  background-image: url(../public/camera3-2.png);
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: fill;
  width: 25px;
  height: 14px;
}

.start-button-wrapper {
  all: unset; /* remove default button styling */
  float: left;
  margin-left: 2px;
  margin-bottom: 2px;
  vertical-align: middle;
  display: flex;
  padding: 2px 6px 3px;
  background-color: silver;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  box-shadow: inset 1px 1px #dfdfdf,1px 0 #000,0 1px #000,1px 1px #000;
  min-width: 59px;
  margin-right: 6px;
  color: #000;
  box-sizing: border-box;
}

.new-button-wrapper {
  all: unset; /* remove default button styling */
  display: flex;
  float: left;
  margin-left: 2px;
  margin-bottom: 2px;
  vertical-align: middle;
  width: 200px; /* Set width for the new button */
  height: 21px; /* Set height for the new button */
  padding: 2px 6px 3px; /* Keep the padding */
  background-color: silver;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
  color: #000;
  box-sizing: border-box;
  text-align: center;
  line-height: 21px; /* Vertically center the text */
  font-size: 14px; /* Adjust font size if necessary */
}

.small-icon-image {
  max-height: 1em;
  vertical-align: middle;
  margin-right: 0.3em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.taskbar-divider {
  width: 2px;
  height: 21px;
  background-color: grey;
}